module.exports = [{
      plugin: require('/build/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TRMBLQ2"},
    },{
      plugin: require('/build/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Volkmann Planung","short_name":"Volkmann Planung","start_url":"/","background_color":"#fff","theme_color":"#4C6CB5","display":"standalone","icon":"./static/images/volkmann-planung.svg","icons":[{"src":"favicon/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"favicon/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"favicon/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"favicon/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"favicon/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"favicon/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"favicon/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"favicon/icon-512x512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('/build/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://39261660cea94ae4a25182c975d07f70@sentry.io/1768415","environment":"production","enabled":true},
    },{
      plugin: require('/build/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
